import React from "react";
import { graphql, Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import Layout from "../../components/Layout";
import postFeedback from "../../components/PostFeedback";
import SEO from "../../components/SEO";

const Solution = ({ data }) => {

    const introduction = data.introduction.nodes;
    const introduction_vi = data.introduction_vi.nodes;
    const deploy_configure_private_domain = data.deploy_configure_private_domain.nodes;
    const deploy_configure_private_domain_vi = data.deploy_configure_private_domain_vi.nodes;
    const integration_eproducts_office_applications = data.integration_eproducts_office_applications.nodes;
    const integration_eproducts_office_applications_vi = data.integration_eproducts_office_applications_vi.nodes;
    const custom_mobile_app_eproducts = data.custom_mobile_app_eproducts.nodes;
    const custom_mobile_app_eproducts_vi = data.custom_mobile_app_eproducts_vi.nodes;
    const customize_eproducts_follow_requirement = data.customize_eproducts_follow_requirement.nodes;
    const customize_eproducts_follow_requirement_vi = data.customize_eproducts_follow_requirement_vi.nodes;
    const built_based_on_technologies = data.built_based_on_technologies.nodes;
    const built_based_on_technologies_vi = data.built_based_on_technologies_vi.nodes;
    const need_more_help = data.need_more_help.nodes;
    const need_more_help_vi = data.need_more_help_vi.nodes;

    function hitButton() {
        var data = {};
        data["type"] = "click";
        data["button"] = "needMoreHelp";
        
        var checkItemLocalStorage = localStorage.getItem("dataNeedMoreHelp");

        if (!checkItemLocalStorage) {
            postFeedback(`${process.env.feedbackAPI}`, data, 
            function(response) {
                console.log(response);
                localStorage.setItem("dataNeedMoreHelp", JSON.stringify(data));
            },
            function(error) {
                console.log("Something went wrong", error);
            });
        }
    };

    return (
        <Layout>
            <SEO title="Solution Service" description="We built our products to be extendable through APIs and are customizable through various settings. Our eProducts can be extended by you, or by our Solutions Engineers who have deep understanding of our products, to save you time and money while provide a hand-in-glove fit into your current environment." meta={[{ name: `keywords`, content: ["extend eProduct with APIs", "customize eProducts", "collaboration app on Microsoft Teams"] }]} pathname="/services/solution" />
            <div className="main-content-padding">
                <div className="app-general-seciton app-solution-services-introduction" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <div className="app-solution-services-introduction-detail">
                            <Row className="app-solution-services-introduction-row-custom">
                                <Col className="app-solution-services-introduction-col-custom" xs={12} md={12} lg={5}>
                                    {introduction.map((res) => (
                                        <div className="app-data-en app-solution-services-introduction-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {introduction_vi.map((res) => (
                                        <div className="app-data-vi app-solution-services-introduction-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={1}></Col>
                                <Col className="app-solution-services-introduction-col-custom" xs={12} md={12} lg={6}>
                                    <div className="app-solution-services-introduction-right">
                                        <img className="app-solution-services-introduction-img" src="/app-solution-services-introduction.png" alt="solution services introduction" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="app-general-seciton app-eProduct-customization-enterprise-demand" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={2}></Col>
                            <Col xs={12} md={12} lg={8}>
                                <div className="app-title">
                                    <h2>eProduct Customization for Enterprise demand</h2>
                                </div>
                            </Col>
                            <Col xs={12} md={12} lg={2}></Col>
                        </Row>
                        <div className="app-eProduct-customization-enterprise-demand-detail">
                            <Row className="app-eProduct-customization-enterprise-demand-detail-row-custom">
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    <div className="app-eProduct-customization-enterprise-demand-detail-left">
                                        <img className="app-eProduct-customization-deploy-configure-private-domain" src="/app-eProduct-customization-deploy-configure-private-domain.jpg" alt="eProduct customization deploy configure private domain" />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    {deploy_configure_private_domain.map((res) => (
                                        <div className="app-data-en app-eProduct-customization-enterprise-demand-detail-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {deploy_configure_private_domain_vi.map((res) => (
                                        <div className="app-data-vi app-eProduct-customization-enterprise-demand-detail-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                            </Row>
                            <Row className="app-eProduct-customization-enterprise-demand-detail-row-custom">
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    {integration_eproducts_office_applications.map((res) => (
                                        <div className="app-data-en app-eProduct-customization-enterprise-demand-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {integration_eproducts_office_applications_vi.map((res) => (
                                        <div className="app-data-vi app-eProduct-customization-enterprise-demand-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    <div className="app-eProduct-customization-enterprise-demand-detail-right">
                                        <img className="app-eProduct-customization-integration-eProduct-office-applications" src="/app-eProduct-customization-integration-eProduct-office-applications.jpg" alt="eProduct customization integration eProduct office applications" />
                                    </div>
                                </Col>
                            </Row>
                            <Row className="app-eProduct-customization-enterprise-demand-detail-row-custom">
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    <div className="app-eProduct-customization-enterprise-demand-detail-left">
                                        <img className="app-eProduct-customization-mobile" src="/app-eProduct-customization-mobile.jpg" alt="eProduct customization mobile" />
                                    </div>
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    {custom_mobile_app_eproducts.map((res) => (
                                        <div className="app-data-en app-eProduct-customization-enterprise-demand-detail-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {custom_mobile_app_eproducts_vi.map((res) => (
                                        <div className="app-data-vi app-eProduct-customization-enterprise-demand-detail-right" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                            </Row>
                            <Row className="app-eProduct-customization-enterprise-demand-detail-row-custom">
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    {customize_eproducts_follow_requirement.map((res) => (
                                        <div className="app-data-en app-eProduct-customization-enterprise-demand-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                    {customize_eproducts_follow_requirement_vi.map((res) => (
                                        <div className="app-data-vi app-eProduct-customization-enterprise-demand-detail-left" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                    ))}
                                </Col>
                                <Col xs={12} md={12} lg={12} xl={6}>
                                    <div className="app-eProduct-customization-enterprise-demand-detail-right">
                                        <img className="app-eProduct-customization-enterprises-requirement" src="/app-eProduct-customization-enterprises-requirement.jpg" alt="eProduct customization enterprises requirement" />
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </div>
                <div className="app-general-seciton app-solutions-are-built-technologies" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={2}></Col>
                            <Col xs={12} md={12} lg={8}>
                                {built_based_on_technologies.map((res) => (
                                    <div className="app-data-en app-solutions-are-built-technologies-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                ))}
                                {built_based_on_technologies_vi.map((res) => (
                                    <div className="app-data-vi app-solutions-are-built-technologies-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                ))}
                            </Col>
                            <Col xs={12} md={12} lg={2}></Col>
                        </Row>
                    </Container>
                </div>
                <div className="app-general-seciton app-need-more-help" data-sal="fade" data-sal-duration="1000" data-sal-delay="100" data-sal-easing="ease">
                    <Container>
                        <Row>
                            <Col xs={12} md={12} lg={2}></Col>
                            <Col xs={12} md={12} lg={8}>
                                {need_more_help.map((res) => (
                                    <div className="app-data-en app-need-more-help-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                ))}
                                {need_more_help_vi.map((res) => (
                                    <div className="app-data-vi app-need-more-help-detail" dangerouslySetInnerHTML={{ __html: res.html }} key={res.id} />
                                ))}
                                <h6><Link to="/contact-us" onClick={hitButton}>Contact Us</Link></h6>
                            </Col>
                            <Col xs={12} md={12} lg={2}></Col>
                        </Row>
                    </Container>
                </div>
            </div>
        </Layout>
    );
};

export default Solution;

export const query = graphql`
    query SolutionServicePage {
        introduction: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_introduction" } } }
        ) {
            nodes {
                html
                id
            }
        }
        introduction_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_introduction_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        deploy_configure_private_domain: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_deploy_configure_private_domain" } } }
        ) {
            nodes {
                html
                id
            }
        }
        deploy_configure_private_domain_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_deploy_configure_private_domain_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        integration_eproducts_office_applications: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_integration_eproducts_office_applications" } } }
        ) {
            nodes {
                html
                id
            }
        }
        integration_eproducts_office_applications_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_integration_eproducts_office_applications_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        custom_mobile_app_eproducts: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_custom_mobile_app_eproducts" } } }
        ) {
            nodes {
                html
                id
            }
        }
        custom_mobile_app_eproducts_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_custom_mobile_app_eproducts_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        customize_eproducts_follow_requirement: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_customize_eproducts_follow_requirement" } } }
        ) {
            nodes {
                html
                id
            }
        }
        customize_eproducts_follow_requirement_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_customize_eproducts_follow_requirement_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        built_based_on_technologies: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_built_based_on_technologies" } } }
        ) {
            nodes {
                html
                id
            }
        }
        built_based_on_technologies_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_built_based_on_technologies_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
        need_more_help: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_need_more_help" } } }
        ) {
            nodes {
                html
                id
            }
        }
        need_more_help_vi: allMarkdownRemark(
            filter: { frontmatter: { directory: { eq: "solution_service_need_more_help_vi" } } }
        ) {
            nodes {
                html
                id
            }
        }
    }
`;
